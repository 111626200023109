import React from "react";
import Select from "react-select";

// styles
import { multiSelectStyles } from "../styles/MultiSelectStyles";

// util
import { options } from "../util/localDB";

const MultipleSelect = (props) => {
  const limitReached = () => {
    return props.contents.length === 5;
  };

  return (
    <Select
      isMulti
      options={limitReached() ? [] : options}
      noOptionsMessage={() => {
        if (limitReached()) return "Limit 5 Allowed";
      }}
      isClearable={false}
      onChange={(val) => {
        val !== null && val.length <= 5
          ? props.handleChange(val)
          : props.handleChange([]);
      }}
      styles={multiSelectStyles}
      placeholder="Add Contents..."
      maxMenuHeight={165}
      value={props.contents}
      menuPlacement={"auto"}
    />
  );
};

export default MultipleSelect;
