import { createMuiTheme } from "@material-ui/core";

export const tableTheme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: "2px solid #B4B4B4",
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#d3d6d9",
      },
    },
    MuiTableCell: {
      root: {
        border: "2px solid #B4B4B4",
        padding: "0em .75em",
      },
      head: {
        color: "#022B3A !important",
      },
    },
  },
});
