import React from "react";

// mui
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { datePickerTheme } from "../styles/DatePickerTheme";

// util
import DateFnsUtils from "@date-io/date-fns";

const theme = createMuiTheme(datePickerTheme);

const DatePicker = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          name={props.name}
          value={props.date}
          onChange={(event, date) => props.handleChange(date)}
          format="MM/dd/yyyy"
          disableToolbar
          fullWidth
          variant="inline"
          margin="none"
          error={props.dateError}
          helperText={props.dateError ? "Error: Invalid Date Range" : ""}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePicker;
