import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import AuthRoute from "./util/AuthRoute";
import { BASE_URL } from "./util/config";

// redux
import { Provider } from "react-redux";
import store from "./redux/store";

// pages
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

// mui
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

// styles
import mainTheme from "./styles/MainTheme";

// setup
axios.defaults.baseURL = BASE_URL;
const theme = createMuiTheme(mainTheme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <AuthRoute exact path="/" component={Dashboard} />
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
