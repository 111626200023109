export const multiSelectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    padding: "2px",
    backgroundColor: "transparent",
    borderColor: isFocused ? "0 0 0 1px #002B3A" : "#002B3A80",
    boxShadow: isFocused ? "0 0 0 1px #002B3A" : "#002B3A80",
    "&:hover": {
      borderColor: "0 0 0 1px #002B3A",
      boxShadow: "0 0 0 1px #002B3A",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "4px",
  }),
  input: (styles) => ({
    ...styles,
    fontSize: ".75em",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: ".75em",
  }),
  option: (styles) => ({
    ...styles,
    fontSize: ".75em",
    fontWeight: "500",
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#022B3A",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
    padding: ".5em !important",
    whiteSpace: "normal !important",
    fontSize: ".75em",
    fontWeight: "500",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    width: "2em",
    justifyContent: "center",
    borderRadius: 0,
    ":hover": {
      backgroundColor: "white",
      color: "#022B3A",
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: "4px",
  }),
  menu: (styles) => ({
    ...styles,
  }),
};
