import React, { useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchLogs } from "../redux/actions/dataActions";
import { SET_FILTERS, RESET_FILTERS } from "../redux/types";

// components
import DatePicker from "./DatePicker";
import MultipleSelect from "./MultipleSelect";

// mui
import { Button, createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

// styles
import "../styles/SideMenu.sass";

// util
import logo from "../util/5-STAR_logo_fullname.png";

const buttonThemes = createMuiTheme({
  palette: {
    primary: {
      main: "#022b3a",
    },
  },
});

const SideMenu = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.data.projects);
  const filters = useSelector((state) => state.data.filters);
  const [dateError, setDateError] = useState(false);

  const handleSubmit = () => {
    if (filters.startDate > filters.endDate) {
      setDateError(true);
    } else {
      setDateError(false);
      dispatch(fetchLogs(projects, filters));
    }
  };

  return (
    <ThemeProvider theme={buttonThemes}>
      <div className="sideMenu-container">
        <img src={logo} alt="5-STAR Logo" className="sideMenu-logo" />

        <div className="sideMenu-filterWrapper">
          <div className="sideMenu-wrapper">
            <h5 className="sideMenu-label">START DATE:</h5>
            <DatePicker
              name="startDate"
              date={filters.startDate}
              handleChange={(date) => {
                let startDate = new Date(date);
                dispatch({
                  type: SET_FILTERS,
                  payload: { ...filters, startDate },
                });
              }}
              dateError={dateError}
            />
          </div>

          <div className="sideMenu-wrapper">
            <h5 className="sideMenu-label">END DATE:</h5>
            <DatePicker
              name="endDate"
              date={new Date(filters.endDate)}
              handleChange={(date) => {
                let endDate = new Date(date);
                dispatch({
                  type: SET_FILTERS,
                  payload: { ...filters, endDate },
                });
              }}
              dateError={dateError}
            />
          </div>

          <div className="sideMenu-wrapper">
            <h5 className="sideMenu-label">CONTENTS:</h5>
            <MultipleSelect
              handleChange={(contents) => {
                dispatch({
                  type: SET_FILTERS,
                  payload: { ...filters, contents },
                });
              }}
              contents={filters.contents}
            />
          </div>
        </div>

        <div className="sideMenu-buttonWrapper">
          <Button
            fullWidth
            color="primary"
            style={{ marginBottom: "1em" }}
            onClick={() => dispatch({ type: RESET_FILTERS })}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => handleSubmit()}
          >
            Search
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SideMenu;
