import {
  SET_PROJECTS,
  SET_LOGS,
  RESET_LOGS,
  SET_FILTERS,
  RESET_FILTERS,
} from "../types";

const initialState = {
  projects: [],
  recentProjects: [],
  logs: [],
  filters: {
    startDate: new Date(),
    endDate: new Date(),
    contents: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case SET_LOGS:
      return {
        ...state,
        logs: [...state.logs, action.payload],
      };
    case RESET_LOGS:
      return {
        ...state,
        logs: [],
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case RESET_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
      };
    default:
      return state;
  }
}
