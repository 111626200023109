export const datePickerTheme = {
  palette: {
    primary: {
      main: "#022B3A",
    },
  },
  overrides: {
    MuiInput: {
      input: {
        fontSize: ".875em",
      },
    },
    MuiPickersBasePicker: {
      container: {
        paddingBottom: ".5em",
      },
    },
    MuiPickersDay: {
      current: {
        color: "#00a5db",
      },
      day: {
        color: "#022B3A",
      },
      daySelected: {
        backgroundColor: "#022b3a",
        "&&:hover": {
          backgroundColor: "#022b3a",
        },
      },
    },
  },
};
