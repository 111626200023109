import axios from "axios";
import {
  SET_PROJECTS,
  SET_LOGS,
  START_LOADING,
  STOP_LOADING,
  RESET_LOGS,
} from "../types";
import { COMPANY_ID, API_VERSION } from "../../util/config";
import { options } from "../../util/localDB";

const MAX_RECENT_DATE = new Date(new Date().setDate(new Date().getDate() - 30));

export const fetchProjects = () => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    let { data: projects } = await axios.get(
      `${API_VERSION}/projects?company_id=${COMPANY_ID}&serializer_view=compact`
    );
    let today = new Date();
    let projectPromises = [];

    projects.forEach((project) => {
      projectPromises.push(
        axios
          .get(
            `${API_VERSION}/projects/${
              project.id
            }/delivery_logs?start_date=${formatDate(
              MAX_RECENT_DATE
            )}&end_date=${formatDate(today)}`
          )
          .then(({ data: logs }) => {
            project["recentlyUpdated"] = logs.length !== 0;
          })
          .catch((err) => {
            console.log(err);
          })
      );
    });
    Promise.all(projectPromises).then(() => {
      dispatch({ type: SET_PROJECTS, payload: projects });
    });
  } catch (err) {
    console.log(err);
  }
};

const formatDate = (date) => {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
    "0" + date.getDate()
  ).slice(-2)}`;
};

export const fetchLogs = (projects, filters) => (dispatch) => {
  dispatch({ type: START_LOADING });
  dispatch({ type: RESET_LOGS });

  // grabs the given Date objects and formats them YYYY-MM-DD per Procore API
  let i = 0;
  let recentOnly = filters.startDate > MAX_RECENT_DATE;
  projects.forEach((project) => {
    if (!(recentOnly && !project.recentlyUpdated)) {
      axios
        .get(
          `${API_VERSION}/projects/${
            project.id
          }/delivery_logs?start_date=${formatDate(
            filters.startDate
          )}&end_date=${formatDate(filters.endDate)}`
        )
        .then(({ data: logs }) => {
          i++;
          // for each project, if there are logs...
          if (logs.length !== 0) {
            // and the user is filtering for specific contents...
            if (filters.contents.length !== 0) {
              let results = logs.filter((log) => {
                let logContents = log.contents.split(/, [\n,\r]*/g);
                // first, check if any of the filters are included in the log contents
                // remember... just need one match for true and the some function stops after the first found instance
                if (
                  filters.contents.some((c) => logContents.includes(c.value))
                ) {
                  return true;
                }
                // next, if no exact matches and user is looking for Other
                else if (filters.contents.some((c) => c.value === "Other")) {
                  // check to see if anything exists in the log contents that isn't a predefined content (i.e. an "other")
                  if (
                    logContents.filter(
                      (c) => !options.some((fc) => fc.value === c)
                    ).length !== 0
                  )
                    return true;
                } else return false;
              });
              // if that project has logs that match our search/filter
              if (results.length !== 0) {
                dispatch({
                  type: SET_LOGS,
                  payload: { project, logs: results },
                });
              }
            } else {
              dispatch({ type: SET_LOGS, payload: { project, logs } });
            }
          }
        })
        .catch((err) => console.log(err))
        .then(() => {
          if (i === projects.length) dispatch({ type: STOP_LOADING });
        });
    } else {
      i++;
      if (i === projects.length) dispatch({ type: STOP_LOADING });
    }
  });
};
