import React, { useEffect } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects, fetchLogs } from "../redux/actions/dataActions";

// mui
import CircularProgress from "@material-ui/core/CircularProgress";

// components
import LogTable from "../components/LogTable";
import SideMenu from "../components/SideMenu";

// styles
import "../styles/Dashboard.sass";

const Dashboard = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loading);
  const projects = useSelector((state) => state.data.projects);
  const logs = useSelector((state) => state.data.logs);
  const filters = useSelector((state) => state.data.filters);

  useEffect(() => {
    if (projects.length === 0) {
      dispatch(fetchProjects());
    }
  }, [projects, dispatch]);

  useEffect(() => {
    if (projects.length !== 0) {
      dispatch(fetchLogs(projects, filters));
    }
  }, [projects, dispatch]);

  return (
    <div className="dashboard-main">
      <SideMenu />
      <div className="dashboard-content">
        {loading && (
          <CircularProgress
            color="primary"
            style={{
              position: "fixed",
              top: "50%",
              left: "58%",
            }}
          />
        )}
        <div className="dashboard-gutters">
          {logs.length !== 0 ? (
            <LogTable data={logs} />
          ) : (
            !loading && (
              <div style={{ textAlign: "center" }}>
                <h1>No Results!</h1>
                <h5>PLEASE TRY AGAIN</h5>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
