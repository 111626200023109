const CLIENT_ID =
  "d4f24f0c95dab99a61d27fafb9e9c05463d721d0b4c144c68c17123555173d49";

export const BASE_URL = "https://api.procore.com/rest";
export const API_VERSION = "/v1.0";

const AUTHORIZATION_ENDPOINT = "https://login.procore.com/oauth/authorize";

//const LOCAL = "http://localhost:3000";
const HOSTED = "https://pods.5starbuild.net/";

export const AUTH_URL =
  AUTHORIZATION_ENDPOINT +
  "?response_type=token" +
  "&client_id=" +
  CLIENT_ID +
  "&redirect_uri=" +
  HOSTED;

export const extractToken = (hash) => {
  var match = hash.match(/access_token=([\w.-]+)/);
  return !!match && match[1];
};

export const COMPANY_ID = "6080";
