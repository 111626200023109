import React, { useEffect, useState } from "react";
import { extractToken, AUTH_URL } from "../util/config";
import axios from "axios";
import logo from "../util/5-STAR_logo.jpg";
import { ReactComponent as ProcoreLogo } from "../util/Procore.svg";
import "../styles/Login.sass";

// MUI
import { Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

// Checks for a valid token and sets Auth header if exists
var token = extractToken(document.location.hash);
if (token) {
  const idToken = `Bearer ${token}`;
  localStorage.setItem("idToken", idToken);
  axios.defaults.headers.common["Authorization"] = idToken;
}

function Login() {
  const [mobile, setMobile] = useState(window.innerWidth > 500 ? false : true);

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth > 500 ? setMobile(false) : setMobile(true);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mobile]);

  return (
    <div className="login-container">
      <Paper elevation={mobile ? 0 : 5} className="login-content">
        <img src={logo} alt="Logo" className="login-logo" />
        <Typography
          variant="h5"
          color="secondary"
          gutterBottom
          style={{ textAlign: "center", marginTop: 50, fontWeight: "bold" }}
        >
          POD Dashboard
        </Typography>
        <Typography variant="subtitle1" color="secondary" align="center">
          Filter and Download PODs
        </Typography>
        <Button
          variant="outlined"
          className="login-button"
          href={AUTH_URL}
          startIcon={<ProcoreLogo className="login-procoreLogo" />}
        >
          LOGIN WITH PROCORE
        </Button>
        <Button color="primary" className="login-helpButton">
          <Link
            color="inherit"
            underline="none"
            href="mailto:it@5starbuild.com"
          >
            Trouble logging in?
          </Link>
        </Button>
      </Paper>
    </div>
  );
}

export default Login;
