import React from "react";

// mui
import { createMuiTheme, TableCell } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const headerTheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        backgroundColor: "#022B3A",
        color: "white !important",
        paddingTop: ".5em !important",
        paddingBottom: ".5em !important",
      },
    },
  },
});

const ProjectHeader = (props) => {
  return (
    <ThemeProvider theme={headerTheme}>
      <TableCell colSpan={props.width}>
        <h3>{props.name}</h3>
      </TableCell>
    </ThemeProvider>
  );
};

export default ProjectHeader;
