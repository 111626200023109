import React from "react";

// components
import ProjectHeader from "../components/ProjectHeader";

// mui
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";

// styles
import { tableTheme } from "../styles/TableTheme";

// util
import { tableHeaders } from "../util/localDB";
import { get } from "lodash";

const LogTable = (props) => {
  return (
    <ThemeProvider theme={tableTheme}>
      <Table>
        <TableHead />

        {props.data.map((project) => {
          return (
            <TableBody key={project.project.id}>
              <TableRow>
                <ProjectHeader
                  name={project.project.name}
                  width={tableHeaders.length}
                />
              </TableRow>
              <TableRow>
                {tableHeaders.map((header) => {
                  return (
                    <TableCell
                      key={header.value}
                      align="center"
                      style={{ padding: "1em" }}
                    >
                      <b>{header.label}</b>
                    </TableCell>
                  );
                })}
              </TableRow>

              {project.logs.map((log) => {
                return (
                  <TableRow key={log.id}>
                    {tableHeaders.map((header) => {
                      return (
                        <TableCell
                          key={header.value}
                          style={{
                            color: "#454545",
                            textAlign:
                              header.label === "DOWNLOAD"
                                ? "center"
                                : "inherit",
                            //Sets the minimum width for all columns except for "Contents"
                            //Contents will grow/shrink as needed
                            minWidth:
                              header.label !== "CONTENTS" ? "115px" : "inherit",
                            maxWidth:
                              header.label !== "CONTENTS" ? "115px" : "inherit",
                            width:
                              header.label !== "CONTENTS" ? "115px" : "inherit",
                          }}
                        >
                          {{
                            DOWNLOAD: (
                              <IconButton
                                onClick={(event) => {
                                  //Downloads all attachments on one button click
                                  event.preventDefault();
                                  let attachments = get(log, header.value, "");
                                  attachments.forEach((attachment) => {
                                    window.open(attachment.url);
                                  });
                                }}
                              >
                                <GetAppIcon />
                              </IconButton>
                            ),
                            "CREATED AT": String(
                              get(log, header.value, "")
                            ).split("T")[0],
                          }[header.label] || get(log, header.value, "")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          );
        })}
      </Table>
    </ThemeProvider>
  );
};

export default LogTable;
