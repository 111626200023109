export const tableHeaders = [
  { label: "USER", value: "created_by.name" },
  { label: "POD DATE", value: "date" },
  { label: "CREATED AT", value: "created_at" },
  { label: "CONTENTS", value: "contents" },
  { label: "DOWNLOAD", value: "attachments" },
];

export const options = [
  { value: "Glue / Caulk", label: "Glue / Caulk" },
  { value: "Hardware - Loose", label: "Hardware - Loose" },
  { value: "Hardware - Tiedown", label: "Hardware - Tiedown" },
  { value: "Header Stock", label: "Header Stock" },
  { value: "LVL / PSL Beam", label: "LVL / PSL Beam" },
  { value: "Nails / Fasteners", label: "Nails / Fasteners" },
  { value: "OSB / Plywood", label: "OSB / Plywood" },
  { value: "OSB T&G", label: "OSB T&G" },
  { value: "PT Lumber", label: "PT Lumber" },
  { value: "Plate Stock", label: "Plate Stock" },
  { value: "Small Tools / Safety", label: "Small Tools / Safety" },
  { value: "Studs", label: "Studs" },
  { value: "Trusses - Floor", label: "Trusses - Floor" },
  { value: "Trusses - Roof", label: "Trusses - Roof" },
  { value: "Wrap / Tape", label: "Wrap / Tape" },
  { value: "Other", label: "Other" },
];
