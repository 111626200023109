// App reducer types
export const RESET_APP = "RESET_APP";

// User reducer types
export const SET_USER = "SET_USER";

// UI reducer types
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";

// Data reducer types
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_LOGS = "SET_LOGS";
export const RESET_LOGS = "RESET_LOGS";
export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
